@media all and (max-width:1024px) and (max-height: 768px)  {
	header {
		nav.main {
			background-color:#FFFFFF;
			position: relative;
		}
		#quickLinks {
			bottom:10px !important;
			.taglineBox {
				height:auto;
				padding: 10px 20px !important;
			}
			ul {
				display:none;
			}
		}
	}
}

@media all and (max-height:1024px){
	#quickLinks:before {
		display:none !important;
	}
}

@media all and (max-width: 1280px)  {
	.taglineBox {
		    padding: 60px 30px !important;
	}
}

@media all and (max-width: 1024px)  {
	#MenuIcon {
		display:inline-block !important;
	}
	nav.primary {
		ul {
			display:none;
		}
	}
	#quickLinks {
		display:block !important;
		height:auto !important;
		.taglineBox {
			width:100%;
			margin-bottom:20px;
		}
		ul {
			text-align:center;
			display:block;
			li {
				margin:0 10px !important;
			}
		}

	}
}

@media all and (max-width: 1000px)  {
	.clearLarge{
		width: 100% !important;
		clear: both;
	}
}
/*iPad*/
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}

@media all and (max-width:950px) {
	.homepageEventsSliderWrapper {
		.title {
			left:0;
			text-align:center;
			margin-bottom:90px;
		}
		.widgetsWrapper {
			display:block;
			.left {
				width:100% !important;
			}
			.right{
				width:100% !important;
			}
		}
	}
}


@media all and (max-width: 768px)  {
	.clearSmall{
		clear: both;
		width: 100% !important;
	}

	.tacMid{
		text-align: center !important;
	}
	nav.main {
		.navInner {
			text-align:center;
			.headerLogo {
				margin:0 auto;
			}
			.headerControls {
				float:none !important;
				width:60%;
				.headerContact {
					.middot {
						display:none;
					}
				}
			}
		}
		.primary {
			ul {
				li {
					display:block;
					margin:5px 0;
					&.menuIconHolder {
						display:block;
					}
				}
			}
		}
	}
	.homepageEventsSliderWrapper {
		.title {
			.top {
				font-size:65px;
			}
		}
		.widgetsWrapper {
			.left {
				h1 {
					margin-left:0;
					font-size:65px;
					text-align:center;
				}
			}
			.right {
				h1 {
					font-size:55px;
				}
			}
		}
	}
	.leftColumn {
		display:block !important;
		#sidebar {
			float:left;
			width:100% !important;
			max-width:100% !important;
		}
	}
	.centerLeft {
		width:100% !important;
		padding:0 10px !important;
	}
	.centerRight {
		display: none !important;
	}
}
@media all and (max-width: 680px)  {
	#quickLinks {
		ul {
			li {
				width:100px;
				height:100px;
			}
		}

	}
	nav.main {
		.navInner {
			.headerLogo {
			}
			.headerControls {
				width:100%;
				text-align:center !important;
				margin-top:10px;
				.middot {
					display: inline !important;
				}
			}
		}
	}
}

@media all and (max-width: 480px)  {

	.tacSmall{
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
	header {
		height:auto !important;
		max-height:none !important;
		min-height:auto !important;
	}
	nav.main {
		position: relative!important;
		.navInner {
			.headerControls {
				float:none !important;
				text-align:center !important;
				.headerContact {
					.middot {
						display:none !important;
					}
				}
			}
		}
	}
	#quickLinks {
		.taglineBox {

		}
	}
}
