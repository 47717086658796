/*
|===============================================================
|	Notes
| + Add .section to :hover class for link which has sub children
|===============================================================
*/

* {
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
} 

.kelvin {
	z-index: 2;
}


html{	
	width: 100%;
	height: 100%;
	//overflow:hidden;
}
body{
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
	margin:0;
	padding:0;
}
.ash{
	opacity: 0;
	height: 0;
	width: 0;
}

ol {
	list-style-type: decimal;
	list-style-type: decimal;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/
.siteMap{
	*{
		font-sizE: 1em;
	}
	ul{
		display: block;
		width: 100%;
		clear: both;
		float: left;

	}
	li{
		display: block;
		width: 100%;
		clear: both;
		float: left;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 10px !important;
	}
}

.pageTitle{
	font-weight: lighter;
	font-size: 2em;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/
.CalendarFilter{
	padding: 10px;
}


.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
	color: #676b71;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}
#allEvents{
	width: 100%;
	max-width: 700px;
	float: left;
	clear: both;
	border-top: 2px solid #eee;
	padding: 20px 0px;
	display: block;
	position: relative;
	ul{margin: 0px;padding: 0px;
		float: left;
		clear: both;
		width: 100%;
		display: block;
		li{
			margin: 3px 0px !important;
			padding: 10px;
			float: left;
			width: 100%;
			clear: both;
			@include border-radius(3px);
			opacity: 1;
			cursor: pointer;
			position: relative;
			@include transition($t1);
			background-color: $themeColor;
			*{color: #fff;}
			&:hover{
				opacity: 0.9;
			}
			sup{
				margin-right: 10px;
			}
			a{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}
	}
}

/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 25px 10px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
	    display: inline-block;
	    padding-top: 5px;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{
	    background-color:$themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #eee  !important;
		color: #000 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}
/*
|===============================================================
|	Scroll Bar
|===============================================================
*/
::-webkit-scrollbar{
	width:10px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color:#eee;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


fieldset{
	border: none;
}

/*** Search Form ***/
.searchBox {
	width:40px;
	height:40px;
	position:fixed;
	background-color: $LinkColor;
	top:0px;
	right:30px;
	background-image:url(../images/search.svg);
	background-size:50% 50%;
	background-position:center 10px;
	background-repeat: no-repeat;
	z-index:5799;
}

#SearchForm_SearchForm {
	width:100%;
	max-width:275px;
	position:fixed;
	left:50%;
	top:50%;
	@include transform(translateX(-50%) translateY(-50%));
	display:none;
	z-index:9999;
	#SearchForm_SearchForm_Search {
		border:1px solid #FFFFFF;
		background-color:#FFFFFF;
		color:$LinkColor;

		    font-style: italic;
     position: absolute; 
    padding: 10px;
    z-index: 8888;
    height: 35px;
    left:0;

    border: 0px;
    width: 200px;
	}
	#SearchForm_SearchForm_action_results{
	    color: #ddd;
	    @include transition($t1);
		@include appearance(none);
	    outline: 0 !important;
	    position: absolute;
	    z-index: 9980;
	    font-family: WebSymbols;
	    text-decoration: none !important;
	    display: inline-block;
	    text-align: center;
	    cursor: pointer;
	    border: none !important;
	    margin-left: 20px;
	    font-size: 2em;
	    background-color: transparent;
	    right:0;
	    width:37px;
	    height:37px;
	    background-image:url(../images/searchIcon.svg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    &:hover{
	    	color: #fff;
	    }
	    i {
	    	color:#FFFFFF;
	    }
	}
}

#blackout, .blackout, .blackoutTranslate {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 5800;
    background-color: rgba(0, 0, 0, 0.85);
}
.noticesBlackout {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(42, 49, 115, 0.85);
}
/*
|===============================================================
|	Project Chart
|===============================================================
*/

table {
	margin: 0 0 2em;
	width: 100%;
	thead {
		tr {
			td{
				text-align: center;
			    padding: 5px;
			    font-size: 1.2em;
			    background-color: $themeColor;
			    padding: 15px 0;
			    color:#FFFFFF;
			    text-transform:uppercase;
			    a {
			    	color:#FFFFFF;
			    }

			}
			
		}
	}
	tbody {
		tr {
			td {
				padding: 0;
				border: 1px solid $themeColor;
			    text-align: center;
			    vertical-align: top;
			    .notesToggle {
			    	cursor:pointer;
			    }
			    .notes {
			    	display:none;
			    	max-width:400px;
			    	margin: 0 auto;
    text-align: justify;
			    }
			    
			}
			&:nth-child(even){
				background-color:$lightGrey;
			}
		}
	}
}

.ApprovalState0, .ApprovalState1 {
	/*display:block;*/
	color:#FFF;
	padding:5px;
	margin:1px 0;

	
}
.ApprovalState0 {
	background-color:#AB1A25;
}

.ApprovalState1 {
	background-color:#407A32;
}

/*
|===============================================================
|	Loading Bar
|===============================================================
*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

/*** Page Loading ***/
.Loading{
	@include transition(opacity 0.35s);
	opacity: 1;
	@include stretchCover(#bedff7, fixed, 9000);
}
.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

#home1{
	padding: 100px 0px
}

/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}

/*** Footer ***/
footer{
	padding: 30px 0px;
}

/*
|===============================================================
|	Homepage News Widget
|===============================================================
*/

.homepageNewsSliderWrapper {
	background-color: #FFFFFF;
	padding:60px 30px;
	position:relative;
	@include transition($t1);
	.titleWrapper {
		max-width:1280px;
		margin:0 auto;
		position: relative;
		h1 {
			text-align: left;
			color:#000000;
			display: inline-block;
			margin-bottom:20px;
			font-size:50px;		
		}
	}
	
	.viewMoreBtn {
		padding:10px 30px;
		background-color:red;
		border-radius:10px;
		color:$LinkColor;
		display:inline-block;
		font-weight:bold;
		@include transition($t1);
		&:hover {
			background-color:$LinkColor;
			color:#FFFFFF;
		}
	}
	.outer {
		max-width:1280px;
		margin:0px auto 30px auto;
		.homepageNewsSlider {
			width:100%;
			/**/
			margin:0 auto;
			display:flex;
			z-index:200;
			.slide {
				z-index:1;
				width:300px;
				height:572px;
				background-color:#FFFFFF;
				.inner {
					/*margin:20px;*/
					border:2px solid $lightGrey;
					padding:40px 20px;
					height:100%;
					position:relative;
					.articleDate {
						color:#000000;
						padding:10px 0 20px 0;
						font-size:14px;
						border-top:1px solid $LinkColor;
						font-weight:700;
					}
					.title {
						color:#000000;
						font-size:24px;
						height:150px;

					}
					.content {
						margin-bottom:60px;
						
					}
					.readMore {
						border:1px solid $LinkColor;
						padding:10px 30px;
						display:inline-block;
						margin:30px 0 0 0;
						@include transition($t1);
						position:absolute;
						left:20px;
						bottom:30px;
						.italic {
							font-style: italic;
						}
						&:hover {
							background-color:$LinkColor;
						}
					}
				}
				section {
					margin-bottom:60px;
					p {
						text-align:justify;
					}
				}
				
			}
		}
	}
	
	.widgetNewsPrev, .widgetNewsNext {
		position: absolute;
		top:10px;
		display:inline-block;
		width:40px;
		height:40px;
		text-align:center;
		border:1px solid $lightGrey;
		z-index:8;
		background-size: 50% 50%;
		background-position: center center;
		background-repeat: no-repeat;
		@include transition($t1);
		a {
			color:$LinkColor;
			width: 100%;
		    height: 100%;
		    display: block;
		}
		&:hover {
			background-color:$LinkColor;
			border:1px solid $LinkColor;
		}
	}
	.widgetNewsPrev {
		right:70px;
		background-image: url(../images/leftBracketBlack.svg);
	}
	.widgetNewsNext {
		right:0px;
		background-image: url(../images/rightBracketBlack.svg);
	}
	&.hidden {
		opacity:0;
	}
	
}


.pageWrapper {
	display:flex;
	.columnLeft {width:300px; display:inline-block; padding:20px;
		.siteName {
			font-size: 18px;

		}
		.componentName {
			margin-left:20px;
			font-size:15px;
			padding:10px;
			&.active {
				background-color:green;
				color:#FFFFFF;
			}
		}
		
	}

	.columnRight{
		 display:inline-block;
		width: calc(100% - 300px);
		/*max-width: 900px;*/
		.HTMLdiv, .CSSdiv, .Responsivediv, .JSdiv {
			display:inline-block;
			margin:20px;
			border:2px solid #999999;
			padding:20px;
			height:300px;
			overflow-y:scroll;
			width:calc(50% - 40px);
			float:left;
		}

		.HTMLinner, .CSSinner, .Responsiveinner, .JSinner, .Notesinner {
			display: none;
		}
	}
	
}



.visible {
	display: block !important;
}

/*
|===============================================================
|	Homepage Upcoming Events Widget
|===============================================================
*/

.homepageEventsSliderWrapper {
	position: relative;
	float: left;
	background-size:cover;
	background-position:center 40%;
	padding:90px 30px 60px 30px;
	background-attachment: fixed;
	width: 100%;
	.title {
		position:relative;
		
		
		.top{
			font-size:83px;
			color:$LinkColor;
			text-align:center;
		}
	}
	.widgetsWrapper {
		background-color:rgba(255,255,255,0.9);
		width:100%;
		max-width:1280px;
		margin:0 auto;
		display:flex;
		position: relative;
    	z-index: 2000;
		.left{
			/*float:left;*/
			h1 {
				margin-left:20px;
			}
		}
		.left, .right {
			width:50%;
			display: inline-block;
			/*float:left;*/
			padding:60px;
			/*height:600px;*/
			h1 {
				color:#000000;
				font-weight: 400;
				font-size: 70px;
			}
			.calendar-widget-table {
				font-family:$font-stack;
				padding-bottom:0;
				thead {
					background-color: transparent;
					th {
						background-color: transparent;
						text-align:left;
						position: relative;
						padding:0 20px 20px 20px;
						.show-month {
							padding:0;
						}
						.prev, .next {
							position: absolute;
							top:0;
							/*@include transform(translateY(-50%));*/
							border:2px solid $LinkColor;
							width:40px;
							height:40px;
							background-position: center center;
							background-size:60% 60%;
							background-repeat: no-repeat;
							
							color:red;
							.inner {
								opacity:0;
							}
							&:hover {
								background-color:$LinkColor;
								border:2px solid $LinkColor;
							}
						}
						.prev {
							right:50px;
							background-image:url(../images/leftBracketBlack.svg);

						}
						.next {
							right:0;
							background-image:url(../images/rightBracketBlack.svg);
						}
					}
				} 
				tbody {
					tr {
						background-color:transparent;
						td {
							background-color:transparent;
							color:#000000;
							border:none;
							font-family: $font-stack;
							padding:15px 0;
						}
					}
				}
			}
		}
		.right {
			h1 {
				.yellow {
					line-height:20px;
				}
			}
			.sliderControls {
				position:relative;
				height:60px;
				margin-bottom:20px;
				.allEvents {
					padding:7px 30px;
					height:40px;
					border:2px solid $LinkColor;
					display:inline-block;
					&:hover {
						background-color:$LinkColor;
					}
				}
				#eventsNext, #eventsPrev {
					position: absolute;
					top:0px;
					display:inline-block;
					width:40px;
					height:40px;
					text-align:center;
					border: 2px solid $LinkColor;
					z-index:9999;
					background-position: center center;
					background-size:60% 60%;
					background-repeat: no-repeat;
					a {
						color:$LinkColor;
						width:100%;
						height:100%;
						display:block;
					}
					&:hover {
						background-color:$LinkColor;
						border:2px solid $LinkColor;
					}
				}
				#eventsPrev {
					right:60px;
					background-image:url(../images/leftBracketBlack.svg);
				}
				#eventsNext {
					right:0px;
					background-image:url(../images/rightBracketBlack.svg);
				}
			}
		}

	}
	#sliderUpcoming {
		width:100%;
		max-width:1280px;
		margin:0 auto;
		section {
			padding-bottom:10px;
			.sliderContent {
				background-color:$LinkColor;
				/*margin-bottom:10px;*/
				padding:15px;
				font-family: $font-stack;
				.date {
					display: inline-block;
					width:40px;
					font-weight: 700;
				}
				&:hover{
					background-color: #000000;
					color:$LinkColor;
					.date {
						color:$LinkColor;
					}
				}
			}
		}
		
		#eventsInner {
			z-index:1;
			section {
				margin-bottom:60px;
				.eventsContent {
					text-align: justify;
				}
			}
		}
	}
	
}


/*
|===============================================================
|	House Points Widget
|===============================================================
*/

.housepointsWrapper {
	width:100%;
	text-align:center;
	.housepointsInner {
		width:100%;
		max-width:1280px;
		margin:0 auto;
	   
    	text-align: center;
		.housepointsItem {
			width:200px;
			display:inline-block;
			margin:20px;
			background-position:center center;
			background-size:cover;
			background-repeat:no-repeat;
			padding:30px;
			.housepointsIcon {
				width:100%;
			}
		}
	}
}




/*
|===============================================================
|	Ticker
|===============================================================
*/

#tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	left:0px;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 46px;
}


.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight: lighter;
}

.tickertape a{
	color: #fff;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	font-weight: lighter;
}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;
	font-size: 14px;
	z-index:999
}

.ticker {
	position: relative;
	max-height: 46px;
	width: 100%;
	background-color: rgba(79,79,79,0.9);
	z-index:4999;
	bottom:200px;
	#LatestNews{
		position: absolute;
	    left: 0px;
	    width:180px;
	    padding-left: 10px;
	    height: 46px;
	    color: #fff;
	    z-index: 9999;
	    line-height: 45px;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding-right: 20px;
	}
}

/*
|===============================================================
|	Gallery 1
|===============================================================
*/

.galleryTemplate-1 {
	.galleryImage {
		position: relative;
		display:inline-block;
		text-align:center; 
		.info {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.click, .bg {
				@include transition(all 0.5s);
			}
			.click {
				color: white;
				opacity: 0;
				z-index: 10;
			}
			.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $mainColor;
				opacity: 0;
				z-index: 5;
			}
		}
		&:hover {
			.info {
				.bg {
					opacity: 0.8;
				}
				.click {
					opacity: 1;
				}
			}
		}
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

.galleryTemplate-2 {

	#bx_slider {
		height: 500px;
		li {
			height: 100%;
			text-align: center;
			img {
				display:inline-block;
				height: 100%;
			}
		}
	}

	.bx_pages_frame {
		position: relative;
		#bx_pages {
			margin-top: 20px;
			li {
				background-position: center center;
				cursor: pointer;
				height: 200px;
			}
		}
		.controls {
			i {
				position: absolute;
				top: 50%;
				color: $mainColor2;
				font-size: 24px;
				margin-top: -12px;
				cursor: pointer;
				@include transition(all 0.5s);
				&:hover {
					opacity: 0.5;
				}
			}
			i.prev {
				left: 0;
			}
			i.next {
				right: 0;
			}
		}
	}

}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }

/*
|===============================================================
|	Page Header - Mock
|===============================================================
*/

header.pageNav {
	background-color: #FFFFFF;
	/*height: 100px;*/
	nav.main {
		position: relative !important;
	}
}

header {
	overflow:hidden;
	position:relative;
	nav.main {
		position:absolute;
		top:0;
		z-index:2;
		width:100%;
		/*height: 80px;
		padding:20px 0;*/
		background-color:rgba(255,255,255,0.6);
		.navInner {
			width:100%;
			/*max-width:1280px;*/
			margin:0 auto;
			position:relative;
			padding:20px 50px 20px 30px;
			.headerLogo {
				position: relative;
				width:200px;
				display:inline-block;
			}
			.headerControls {
				/*width:50%;
				position:absolute;
				right:30px;
				top:10px;*/
				display: inline-block;
				text-align:right;
				float:right;
				.socialIcon {
					width:30px;
					height:30px;
					/*border-radius: 15px;*/
					/*background-color:#FFFFFF;*/
					display: inline-block;
					cursor:pointer;
					background-position: center center;
						background-size: contain;
						background-repeat: no-repeat;
						margin:0 10px;
						@include transition($t1);
					/*&:hover {
						background-color:$LinkColor;
					}*/
					&.twitter {
						background-image: url(../images/twitterIcon.svg);
						
					}
					&.facebook {
						background-image: url(../images/facebookIcon.svg);
					}
					&.searchIcon {
						background-image: url(../images/searchIconBlack.svg);
					}
					
				}
				.headerContact {
					color:$LinkColor;
					display:inline-block;
					white-space: nowrap;
					a {color:$LinkColor;}
					.middot {
						color:$LinkColor;
					}
				}
			}
		}
		
	}
	.taglineBoxTop {
		height: 200px;
		width:100%;
	    display: inline-block;
	    background-color: rgba(0,156,212,0.7);
	    text-align: center;
	    color: #FFFFFF;
	    font-size: 30px;
	    font-weight: 100;
	    padding: 80px 30px;
	    position:absolute;
	    left:0;
	    bottom:250px;
	    z-index:2;
	}

	/*.swoosh {
		position:absolute;
		left:-10vw;
		bottom:20vh;
		height:60vh;
		width:100%;
		background-size:contain;
		background-position:left bottom;
		background-repeat: no-repeat;
		background-image:url(../images/swoosh.svg);
		z-index:2;
		opacity:0.7;
	}*/
	.pageHeader {
		background-color: #f4f4f4;
		width: 100%;
		height: 500px;
	}
	.flexslider {
	overflow:hidden;
		ul {
		overflow:hidden;
			li {
			overflow:hidden;
				position:relative;
				.slideBG {
					background-position:center center;
					background-size:cover;
					margin-top: 0 !important;
				}
			}
		}
	}
	nav.primary {
		width:100%;
		text-align:center;
		background-color:$LinkColor;
		border-top:2px solid $lightGreen;
		border-bottom:2px solid $lightGreen;
		ul {
			li {
				display:inline-block;
				padding:0 30px;
				&.menuIconHolder {
					display:none;
					text-align:center;
					.menuIcon {
						width:30px;
						height:30px;
						background-size:80% 80%;
						background-position:center center;
						display:inline-block;
						background-image:url(../images/menuIcon.svg);
						background-repeat:no-repeat;
					}
				}
				a {
					color:#FFFFFF;
					text-transform: uppercase;
					font-size:15px;
					font-weight:100;
					@include transition($t1);
					&:hover {
						color:$lightGreen;
					}				}
			}
		}
	}
}

.contentWrapper {
	display:block;
	width:100%;
	padding:30px;
	.contentInner {
		margin:0 auto;
		width:100%;
		max-width:1280px;
		font-weight:100;
		h1 {
			text-align:center;
			font-weight:500;
		}
		a {
			color:$LinkColor;
			font-weight:500;
		}
	}
}

/*
margin-top: 20px;
|===============================================================
|	Home Page Footer - Mock
|===============================================================
*/

.homepageFooter {
	width: 100%;
	border-top:2px solid $lightGreen;
	background-color: $LinkColor;
	color:#FFFFFF;
	text-align:center;
	padding:10px 30px;
	text-transform:uppercase;
	font-weight:100;
	font-size:15px;
}



.leftColumn {
	display: block;
}
#breadcrumbs {
	background-color:$LinkColor;
	padding:5px 0;
	margin:0 0 60px 0;
	.inner {
		max-width:1280px;
		width:100%;
		margin:0 auto;
	}
	a {
		color:#000000;
		font-size:16px;
	}
}

.centerColumn {
	width:100%;
	max-width:1280px;
	margin:0 auto;
	/*display:flex;*/
	.centerLeft {
		width:100%;
		display: inline-block;
		/*float: left;*/
		padding:90px 20px;
		a {
			color:$LinkColor;
		}
		h1 {
			display: block;
			color:$LinkColor;
			padding-bottom:10px;
			margin-bottom: 60px;
		}
		ul {
			margin-bottom:20px;
			li {
				list-style-type: disc;
				margin-left:30px;
			}
		}
		 
		.subjectAreaItem {
			display: block;
			margin:10px 0;
		}
		img {
			margin-right:20px;
		}

		.addToCalendar {

			img {
				margin-right:20px;
			}
		}
		.userform{
			.field {
				margin:30px 0;
				label {
					color:$LinkColor;
					/*font-family: $font-museo;*/
					font-weight: 100;
				}
				.text, .textarea {
					min-width:250px;
					width:75%;
					padding:10px;
					border: 1px solid #666666;
					margin-top:10px;
				}
				&.checkbox {
					width:75%;
					.middleColumn {
						display:inline-block;
					}
				}
			}
			.action {
				min-width:250px;
				width:75%;
				padding:10px;
				border: 1px solid $LinkColor;
				border-radius:0;
				background-color:$LinkColor;
				margin-bottom:30px;
				color:#FFFFFF;
				@include transition($t1);
				&:hover {
					background-color:$lightGreen;
					color:$LinkColor;
				}
			}
			span.error {
			  display: block;
			  border: 1px solid #da8486;
			  margin: 0px 0px 10px 0px;
			  padding: 5px 20px;
			  font-size: 1em;
			  color: #da8486;
			  background-color: #ffdfdf;
			  width:75%;
			  margin-top:20px;
			}
		}
		.galleryWrapper {
			display: inline-block;
			border:2px solid $lightGrey;
			padding:20px;
			position: relative;
			margin:0 20px 30px 0;
			overflow:hidden;
			.galleryDecoration {
				.galleryImageSm {
					width:250px;
					height:250px;
					border-radius:25px;
					background-size: cover;
					background-position:center center;
				}

			}
			.galleryTitle {
				text-align: center;
				margin:20px 0;
				border-top:1px solid $LinkColor;
				padding-top:20px;
				@include transition($t1);
			}
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				@include transition($t1);
				opacity:0;
			}
			&:hover {
				.galleryTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
			
		}
		.galleryImage {
			display: inline-block;
			border:2px solid $lightGrey;
			padding:20px;
			position: relative;
			margin:0 20px 30px 0;
			overflow:hidden;
			.thumb {
				width:200px;
				height:200px;
				border-radius:25px;
				background-size: cover;
				background-position:center center;
			}
			.galleryImageTitle {
				text-align: center;
				margin:20px 0 0 0;
				border-top:1px solid $LinkColor;
				padding-top:20px;
				@include transition($t1);
			}
			&:after {
				content:'';
				width:100%;
				height:100%;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				opacity:0;
				@include transition($t1);
			}
			&:hover {
				.galleryImageTitle {
					border-top:1px solid #000000;
				}
				&:after {
					opacity:1;
				}
			}
		}

		.Newsletter {
			border:2px solid $lightGrey;
			padding: 20px;
			margin:0 0 20px 0;
			position: relative;
			overflow:hidden;
			&:after {
				content:'';
				width:100%;
				height:100%;
				opacity:0;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				@include transition($t1);
			}
			&:hover {
				border:2px solid #000000;
				&:after {
					opacity:1;
				}
			}
		}
		#SearchResults li {
			margin-bottom:30px;
		}
		.staffProfile {
			.staffProfilePic {
				width:200px;
				height:200px;
				background-size: cover;
				background-position:center center;
				border-radius:20px;
			}
		}
		/*.Upload {
			border:2px solid $lightGrey;
			padding: 20px;
			margin:0 0 20px 0;
			position: relative;
			overflow:hidden;
			&:after {
				content:'';
				width:100%;
				height:100%;
				opacity:0;
				background-color: $LinkColor;
				position: absolute;
				left:0;
				bottom:0;
				z-index:-1;
				@include transition($t1);
			}
			&:hover {
				border:2px solid #000000;
				&:after {
					opacity:1;
				}
			}
		}*/
		.news-teaser {
			margin-bottom:60px;
			.newsHolderImage {
				display:inline-block;
				float:left;
				margin:0 20px 20px 0;
				img {
					border-radius:30px;
				}
				.defaultImage {
					width:150px;
					height:150px;
					background-image: url(../images/newsDefault.jpg);
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;
					display:inline-block;
				}
			}
			.newsHolderText {
				.content {
					width: calc(100% - 170px);
    				display: inline-block;
				}
				.button-hover {
					display:inline-block;
					margin-top:30px;
					color:$LinkColor;
				}
			}
			.readMore {
				color:$LinkColor;
				&:hover {
					color:$LinkColor;
				}
			}
		}
		.contentWrapper {
			margin-bottom:60px;
		}
		.NewsletterItem {
			width:250px;
			border:2px solid $LinkColor;
			padding:20px;
			display:inline-block;
			margin:0 20px 20px 0;
			@include transition($t1);
			.newsletterThumb {
				width:100%;
				height:280px;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
			}
			.newsletterTitle {
				width: 100%;
				display:block;
				text-align:center;
			}
			&:hover {
				background-color:$LinkColor;
			}
		}
		.HorizontalStack {
			.staffProfile {
				width:auto;
				margin:0 30px 30px 0;
				.profilePic {
					margin:0 !important;
				}
			}
		}
		.fleetObjectWrapper {
			margin-top:60px;
			.fleetObject {
				width:300px;
				height:200px;
				background-size: cover;
				background-position: center center;
				display:inline-block;
				margin:0 20px 20px 0;
				border:2px solid $lightGreen;
				cursor:pointer;
				opacity:0.8;
				@include transition($t1);
				&:hover {
					border:2px solid $LinkColor;
					opacity:1;
				}
			}
		}

		.fleetBlackout {
			position: fixed;
			width:100vw;
			height:100vh;
			top:0;
			left:0;
			background-color:rgba(0,0,0,0.6);
			z-index:2001;
			display:none;

			
		}
		.fleetDisplayBox {
			/*margin:30px;*/
			width:calc(100vw - 60px);
			max-width:800px;
			height:calc(100vh - 60px);
			overflow-y:scroll;
			background-color:#FFFFFF;
			position: fixed;
			left:50%;
			top:50%;
			@include transform(translate(-50%, -50%) scale(0));
			@include transition($t2);
			z-index:2002;
			display:inline-block;
			padding:20px;
			@include transition-delay(0.5s);
			.fleetDisplayImage {
				width:100%;
				/*height:400px;*/
				background-size:contain;
				background-repeat: no-repeat;
				background-position:center center;
			}
			.fleetDisplayTitle {
				padding:30px 0;
				font-weight:100;
			}
			&.show {
				@include transition-delay(0s);
				@include transform(translate(-50%, -50%) scale(1));
			
			}
			.fleetDisplayClose {
				text-align:right;
				.close {
					cursor:pointer;
					text-transform: uppercase;
					.fa {
						vertical-align:middle;
						margin-left:20px;
					}
				}
				&:after {

				}
			}
		}
		
	}
	.centerRight {
		width:25%;
		display: inline-block;
		padding: 0 10px;
		margin-top:60px;
		max-width:330px;
		min-width:250px;
		.homeworkBtn {
			width:100%;
			padding:30px ;
			background-color:#8e438f;
			color:#FFFFFF;
			text-align:center;
			border-radius: 30px;
			@include transition($t1);
			&:hover {
				background-color:$LinkColor;
				color:$LinkColor;
			}
		}
	}
}

.HouseBox {
			display:block;
			width:100%;
			padding:30px;
			border:2px solid $LinkColor;
			margin-bottom:60px;
			display:flex;

			.houseLogo {
				width:250px;
				height:250px;
				background-size:contain;
				background-position: center center;
				background-repeat:no-repeat;
				margin-right:30px;
				display:inline-block;
			}
			.houseInfo {
				width:calc(100% - 280px);
				h1 {
					margin-bottom:10px;
				}
				.pointsBox {
					margin-bottom:40px;
					.pointsBar {
						height:10px;
					}
				}
				.attendanceBox {
					margin-bottom:40px;
					.pointsBar {
						height:10px;
					}
				}
			}
		}
		.ClassPersonBox {
			display:block;
			width:100%;
			padding:30px;
			border:2px solid $LinkColor;
			margin-bottom:60px;
			display:flex;
			.classPersonImage {
				width:250px;
				height:250px;
				background-size:contain;
				background-position: center center;
				background-repeat:no-repeat;
				margin-right:30px;
				display:inline-block;
			}
			.classPersonInfo {
				width:calc(100% - 280px);
				h1 {
					margin-bottom:10px;
				}
			}
		}


/*
|===============================================================
|	Page Footer - Mock
|===============================================================
*/

pageFooter {
	width: 100%;
	min-height: 400px;
	background-color: $mainColor2;
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

/*
|===============================================================
|	Contact Form
|===============================================================
*/

.contactForm {
	padding: 50px 0;
	float: left;
	.col-6 {
		float: left;
	}
}

/*
|===============================================================
|	Home Page 2 Column Section
|===============================================================
*/


.homepageTwoColumnWrapper {
	position:relative;
	float:left;
	display:flex;
}



/*
|===============================================================
|	Twitter
|===============================================================
*/

.homepageTwitter {
	position:relative;
	float: left;
	padding:30px;
	background: $textLight;
	.twitterPrev, .twitterNext {
		width:20px;
		height:20px;
		position:absolute;
		top:50%;
		@include transform(translateY(-50%));
		color:#FF00FF;
		cursor:pointer;
	}
	.twitterPrev {
		content:'⇦';
		left:-40px;
	}
	.twitterNext {
		content:'⇨';
		right:-40px;
	}
	section {
		max-width:1280px;
		width:100%;
		margin:0 auto;
		padding:20px;
	}
	.homepageTwitterThumb {
		width:120px;
	}
}

#tweets {
	position: relative;
	padding: 0;
	
	h1 {
		font-weight: 300;
		text-transform: uppercase;
		text-align: center;
	}
	.tweets {
		text-align: center;
		.tweetFrame {
			display: inline-block;
			width: 100%;
			max-width: 550px;
			.tweetItem {
				width: 100%;
				color: $mainColor2;
				padding-bottom: 20px;
				p {
					font-weight: 300;
					color: black;
					line-height: 30px;
					font-size: 25px;
					height: 300px;
				}
			}

			.frame {
				border-top: 2px solid black;
			}
			.tweetHandle {
				a {
					font-weight: 300;
					color: red;
				}
			}
			.tweetMeta {
				width: 35%;
				padding: 10px 0;
				text-align: left;
				float: left;
				.meta1, .metaRetweet, .metaLike {
					display: inline-block;
					margin-right: 15px;
					svg {
						path {
							fill: black;
						}
					}
					svg:hover {
						path {
							fill: red;
						}
					}
				}
			}
			.tweetData {
				float: left;
				width: 65%;
				text-align: right;
				padding-top: 15px;
				text-align: right;
				p {
					color: $mainColor;
					display: inline-block;
					margin-left: 10px;
					display: inline-block;
					height: auto;
					font-size: 20px;
					span {
						color: blac;
					}	
				}
			}
		}
	}
	.controlNav {
		position: relative;
		.prev, .next {
			position: relative;
			border: 1px solid $mainColor;
			display: inline-block;
			padding: 10px;
			margin: 0;
			width:30px;
			height: 30px;
			line-height: 0px;
			cursor: pointer;
			a {
				width:100%;
				height:100%;
				display:block;
			}
		}
		.next {
			margin-left: 20px;
		}
		.prev:hover, .next:hover {
			background: red;
			svg {
				polygon {
					fill: white;
				}
			}
		}
	}
	.bgs {
		position: absolute;
		display: none;
		width: 100%;
		height: 100%;
		.yellow {
			position: relative;
			background-color: white;
			width: 50%;
			height: 100%;
		}
	}
}

/*
|===============================================================
|	Google Map
|===============================================================
*/
#gmap_canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

}
.mapBox {
	position: relative;
	width: 100%;
	padding: 50px;
	min-height:500px;

	.address {
		position: relative;
		background: rgba(255,255,255,0.8);
		padding: 80px;
		display: inline-block;
		z-index: 4000;
		ul {
			padding-left: 0;
			margin: 0;
			padding-right: 20px;
			li {
				list-style: none;
				margin-bottom: 5px;
				h3, i, p span {
					color: $mainColor2;
				}
				h3 {
					margin-top: 0px;
					font-weight: 100;
					span {
						font-weight: 700;
					}
				}
				p {
					color: $mainColor;
					font-size: 22px;
					margin-bottom: 0;
					font-weight: 100;
				}
				i {
					display: inline-block;
				}
			}
		}
		li.contact {
			margin-top: 40px;
		}
	}
}

/*
|===============================================================
|	Notices Modal
|===============================================================
*/
#modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	left:0;
	top:0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color:rgba(0,0,0,0.5);
	z-index: 9999;
	.modalWrapper {

		padding: 40px;
		width: 500px;

		background: rgba(0,0,0,0.7);
		padding: 40px;
		text-align:center;
		.modalBox {
			
			width: 500px;
			/*height: 300px;*/
			
			ul {
				li {
					height: 150px;
				}
			}
			h1 {
				color: $LinkColor;
			}
			h1, p {
				color: $LinkColor;
				text-align: center;
			}
			.bx-pager {
				width: 100%;
				text-align: center;
				.bx-pager-item {
					position: relative;
					display: inline-block;
					margin: 0 5px;
					a.bx-pager-link {
						position: relative;
						display:block;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						text-indent: -9999px;
						background: $LinkColor;
						opacity: 0.5;
					}
					a.bx-pager-link.active {
						opacity: 1;
					}
				}
			}
			
		}
		.close {

			padding:10px 30px;
			text-align:center;
			display:block;
			border:2px solid $LinkColor;
			margin:30px auto 0 auto;
			background-color:transparent;
			color:$LinkColor;
			text-transform: uppercase;
			@include transition($t1);
			cursor:pointer;
			&:hover {
				background-color:$LinkColor;
				color:#000000;
			}
		}
	}
	
}


.priorityAlert {
	position:absolute;
	-webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    left:50%;
    top:50%;
    width:30%;
    min-width:240px;
    padding:40px;
    text-align: center;
    font-size:30px;
    background-color:$LinkColor;
    line-height:40px;
    color:#FFFFFF;
    z-index:1;
    .priorityAlert a {
		color:#FFFFFF;
	}

	.priorityAlertClose {
		display:block;
		width:100%;
		color:#FFFFFF;
		font-size:16px;
		line-height:40px;
		margin-top:30px;
		cursor:pointer;
	}
}

.priorityAlert a {
	color:#FFFFFF;
}

.priorityAlertClose {
	display:block;
	width:100%;
	color:#FFFFFF;
	font-size:16px;
	line-height:40px;
	margin-top:30px;
	cursor:pointer;
}

/*
|===============================================================
|	Quick Links
|===============================================================
*/

#quickLinks {
	
	display: flex;
	position: absolute;
	margin-bottom:30px;
	height:200px;
	/*text-align: center;*/
	z-index: 2000;
	/*overflow: hidden;*/
	width:100%;
	text-align:center;
	&.innerPage {
		position:relative !important; 
	}
	
	.taglineBox {
		width:calc(100% - 700px);
		height:100%;
		display:inline-block;
		background-color: rgba(0,156,212,0.45);
		text-align: center;
	    color: #FFFFFF;
	    font-size: 30px;
	    font-weight: 100;
	    padding: 80px 30px;
	    /*background-image:url(../images/logopair.svg);*/
	    background-size:100% 80%;
	    background-repeat: no-repeat;
	    background-position:center center;
	}
	.taglineBoxAfter {
		width:20px;
		height:100%;
		display:inline-block;
		background-color: rgba(0,156,212,0.45);
		margin:0 0 0 20px;
	}
	ul{
		position: relative;
		display:inline-block;
	}
	.quicklinkObject {
		/*width: calc(20% - 20px);*/
		width:200px;
		height: 200px;
		min-width:200px;
		min-height: 200px;
		max-width:200px;
		max-height: 200px;
		align-items: center;
		background-position: center center;
		background-size: cover;
		position: relative;
		/*padding-left: 20px;
		margin: 0 20px 0 0;*/
		margin:0 0 0 20px;
		color: white;
		font-weight: 100;
		display: inline-block;
		opacity:1;
		/*@include transform(translateY(-100%));*/
		@include transition($t1);
		border:1px solid $lightGreen;
		a {
			display:block;
			width:100%;
			height:100%;
			@include transition($t1);
			font-size:20px;
			
		}
		&:hover {
			border:1px solid $LinkColor;
		}

		
		

	}
}

/*
|===============================================================
|	Useful Links
|===============================================================
*/

#usefulLinks {
	max-width:1280px;
	width: 100%;
	text-align:center;
	li {
		width: 300px;
		margin: 10px;
		display: inline-block;
		align-items: center;
		text-align:center;
		background: $mainColor;
		padding: 20px;
		color: white;
		font-weight: 100;
		.titleText {
			display:block;
		}
		.homepageQLIcon {
			width:100px;
			height:100px;
			background-size:contain;
			background-position:center;
			display:inline-block;
		}
		
		i {
			color: white;
			font-size: 30px;
		}
	}
}


/*
|===============================================================
|	Content
|===============================================================
*/


.contentMiddleColumn {
	padding: 50px 0;

	.sideBarFrame {
		border-right: 2px solid $mainColor;
		min-height: 200px;
	}

	.contentFrame {
		padding-left: 20px;
	}

}

/*
|===============================================================
|	Vacancy
|===============================================================
*/

.vacancyHolder {
	.vacancy__item {
		border-bottom: 1px solid $mainColor;
		h4 {
			padding-top: 20px;
			font-weight: 300;
			span {
				font-weight: bold;
			}
		}
	}
}

/*
|===============================================================
|	Welcome Message
|===============================================================
*/
#welcomeMessage {
	padding: 50px 0;
	text-align: center;
}

/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToTop {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background: grey;
	padding: 10px;
	border-radius: 50%;
	z-index: 4000;
	cursor: pointer;
	i {
		color: white;
	}
}
/*
|===============================================================
|	Scroll Top
|===============================================================
*/

.scrollToBottom {
	position: absolute;
	bottom: 30px;
	left: 50%;
	width: 50px;
	height: 50px;
	margin-left: -30px;
	background: grey;
	padding: 10px;
	border-radius: 50%;
	z-index: 4000;
	cursor: pointer;
	i {
		color: white;
	}
}

/*
|===============================================================
|	Calendar
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	padding-bottom: 50px;
	thead {
		tr {
			th {
				border: none;
				@include border-radius(3px);
    			padding: 15px 20px;
				background-color:$LinkColor;
				.show-month, .next, .prev {
					color: #FFFFFF;
					font-weight: bold;
					display:inline-block;
					font-weight: lighter;
    				font-size: 0.7em;
					i{
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color:$LinkColor;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			@include transition($t1);
			color: #a9afb9;
		}
		tr {
			&:nth-child(even){ background-color:transparent; }
			td {
				padding: 20px;
			}
		}
		
		
		.hasEvent {
			    background-image: url(../images/calendarIcon.svg);
    			background-repeat: no-repeat;
    			background-position: 5px 5px;
    		    background-size: 15px 15px;
		}
		.today {
			/* color:$LinkColor; */
			color: #676b71;
		}
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			opacity:0.2;
		}
	} 
	.calendar-day:hover, .selected {
		background-color: $LinkColor !important;
		color: #FFFFFF;
		font-weight: 500;
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter Isotope
|===============================================================
*/

.button {
  display: inline-block;
  padding: 0.5em 1.0em;
  background: #EEE;
  border: none;
  border-radius: 7px;
  background-image: linear-gradient( to bottom, hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.2) );
  color: #222;
  font-family: sans-serif;
  font-size: 16px;
  text-shadow: 0 1px white;
  cursor: pointer;
}

.button:hover {
  background-color: #8CF;
  text-shadow: 0 1px hsla(0, 0%, 100%, 0.5);
  color: #222;
}

.button:active,
.button.is-checked {
  background-color: #28F;
}

.button.is-checked {
  color: white;
  text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
}

.button:active {
  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}

/* ---- button-group ---- */

.button-group {
  margin-bottom: 20px;
}

.button-group:after {
  content: '';
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  margin-left: 0;
  margin-right: 1px;
}

.button-group .button:first-child { border-radius: 0.5em 0 0 0.5em; }
.button-group .button:last-child { border-radius: 0 0.5em 0.5em 0; }

/* ---- isotope ---- */

.grid-item {
	width: 25%;
}
.event-inner{
	margin: 10px;
	padding: 10px;
	background: #ccc;
}


/* end isotope */


.events_page {
	h2.title {
		color: $mainColor2;
		font-weight: 300;
		text-transform: uppercase;
	}
	a {
		color: grey;
	}
	a.url {
		color: $mainColor2;
		font-weight: 300;
	}
}


/*
|===============================================================
|	Blackout
|===============================================================
*/
.translate {
	cursor: pointer;
}

#google_translate_element {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 9999;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); 
}

#blackout {
	position: absolute;
	display: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.5);
	z-index: 5000;
}

/*
|===============================================================
|	Login Form
|===============================================================
*/
body.Page.Security {
	#CustomLoginForm_LoginForm {
		label {
			position: relative;
			margin-bottom: 10px;
		}
		input {
			padding: 10px;
			border: none;
			background-color: #eeeeee !important;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		input[type=submit] {
			background-color: $mainColor !important;
			text-transform: uppercase;
			color: white;
			&:hover {
				background-color: $HeadingColor !important;

			}
		}
	}
}


/*
|===============================================================
|	Breadcrumbs
|===============================================================
*/
#breadcrumbs {
	background-color: $mainColor;
	padding: 10px 0;
	padding-left: 60px;
	p {
		color: white;
		margin: 0;
		span {
			color: white;
		}
	}
}
